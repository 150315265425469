/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        $(".firstsection").on("click", function (e) {
          e.preventDefault();
          var target_offset = $("header.banner").offset().top;
          $("html, body").animate(
            {
              scrollTop: target_offset,
            },
            500
          );
        });
        $(".home .navbar-brand").on("click", function (e) {
          e.preventDefault();
          $("html, body").animate({ scrollTop: 0 }, 500);
        });
        var swiperIndex = 0;
        document
          .querySelectorAll(".swiper-galleries")
          .forEach(function (element) {
            var galleryId = "swiper-galleries-" + swiperIndex;
            element.id = galleryId;

            new Swiper("#" + galleryId, {
              spaceBetween: 0,
              loop: true,
              effect: "fade",
              lazy: true,
              centeredSlides: true,
              autoplay: {
                delay: 5000,
                disableOnInteraction: true,
              },
              pagination: {
                el: "#" + galleryId + " .swiper-pagination",
                clickable: false,
              },
            });

            swiperIndex++;
          });
        $("img.lazy, div.lazy").lazyload({
          effect: "fadeIn",
          failure_limit: Math.max($("img.lazy").length - 1, 0),
        });
        var loader =
          '<div class="ajax-loader">' +
          '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">' +
          '<g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="2">' +
          '<circle stroke-opacity=".5" cx="18" cy="18" r="18"/>' +
          '<path d="M36 18c0-9.94-8.06-18-18-18">' +
          '<animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/>' +
          "</path></g></g></svg></div>";
        var ajaxmodal = $("#ajaxmodal");
        $(".post-link").click(function () {
          var post_link = $(this).attr("data-url");
          ajaxmodal.attr("data-permalink", post_link);
        });
        ajaxmodal.on("show.bs.modal", function (e) {
          window.history.pushState("forward", null, "#ajaxmodal");
        });
        $(window).on("popstate", function () {
          ajaxmodal.modal("hide");
        });
        ajaxmodal.on("shown.bs.modal", function (e) {
          var permalink = $(this).attr("data-permalink") + " #ajaxsection";
          ajaxmodal.find(".ajaxcontent").html(loader);
          ajaxmodal.find(".ajaxcontent").load(permalink);
          return false;
        });
        ajaxmodal.on("hide.bs.modal", function (e) {
          ajaxmodal.attr("data-permalink", "");
          ajaxmodal.find(".ajaxcontent").html("");
          history.pushState("", document.title, window.location.pathname);
        });
        $(document).ajaxComplete(function () {
          var galleriesSwiper = new Swiper(".swiper-galleries", {
            spaceBetween: 0,
            loop: true,
            effect: "fade",
            lazy: true,
            centeredSlides: true,
            autoplay: {
              delay: 5000,
              disableOnInteraction: true,
            },
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            pagination: {
              el: ".swiper-pagination",
              clickable: false,
            },
          });
        });
        $(".grid").isotope({
          // options...
          itemSelector: ".item",
          masonry: {
            columnWidth: ".item",
          },
        });
      },

      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
